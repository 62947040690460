
// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&family=Montserrat:wght@500;700&family=Oxygen:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

// Fonts
$titleFont:     'Oxygen', sans-serif;
$bodyFont:      'Manrope', sans-serif;

$h-font:       'Roboto', sans-serif;
$b-font:       'Roboto', sans-serif;
$h-weight:      Bold;
$b-weight:      Regular;


// Custom Colours
$white:          #ffffff;
$black:          #000000;
$grey-dark:      #2E2E2E;
$grey-mid:       #6A6A6A;
$grey-light:     #EBE9E8;
$red-dark:       #4C071B;
$red:            #E12328;
$orange:         #F37D21;
$orange-dark:    #A82F1E;
$yellow:         #FEB214;
$pagination-hover-color: $red;

// Custom vairables
$global-gutter:  2em;

// Bulma overides
$size-1:                     3rem;
$size-2:                     2.5rem;
$size-3:                     2rem;
$size-4:                     1.5rem;
$size-5:                     1.2rem;
$size-6:                     1rem;
$size-7:                     0.75rem;

$body-size:                  19px;
$family-sans-serif:          $bodyFont;
$title-family:               $titleFont;

$title-weight:               400;
$title-color:                $red;
$text:                       $black;
$link:                       $red;
$link-hover:                 $yellow;
$primary:                    $red;
$warning:                    $yellow;
$danger:                     $red-dark;

$section-padding:            3rem 1.5rem;
$section-padding-medium:     6rem 4.5rem;
$section-padding-large:      12rem 6rem;


$content-blockquote-background-color:   none;
$content-blockquote-border-left:        none;
$content-blockquote-padding:            0 1.5em;

//Headings
h1{

	font-size:  23px !important;
}
h1,h2,h3,h4,h5,h6 {
	font-family: $h-font;
	font-weight: $h-weight;
}
p{
	font-family: $b-font;
	font-weight: $b-weight;
}
//Buttons
.b-button {
	background-color: $primary;
	color: $white;
	font-size: 20px;
  font-weight: 600;
  border-radius: 0;
  padding: 20px 25px;
  border: none;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
}
html {
  scroll-behavior: smooth;
}
// .columns { max-width: 100%; }

//Custom Breakpoints and mixins
$b-mobile: 768px;
$b-desktop: 1024px;
$ut-desktop: 1025px;
$ul-dekstop: 1215px;
$bm-mobile: 520px;
$bs-mobile: 440px;
$b-large: 1536px;
$select-large: 1408px;

@mixin devices ($breakpoint) { //the name of the mixin is devices
  @if $breakpoint == only-tablet {    
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  }
  @if $breakpoint == s-mobile {    
    @media (max-width: 1023px) {
      @content;
    }
  }
  @if $breakpoint == desk-above {    
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == u-desktop {    
    @media (min-width: 1025px) {
      @content;
    }
  }
  @if $breakpoint == only-desktop {    
    @media (min-width: 1024px) and (max-width: 1215px) {
      @content;
    }
  }
  @if $breakpoint == laptop-desktop {    
    @media (min-width: 1024px) and (max-width: 1408px) {
      @content;
    }
  }
  @if $breakpoint == s2-desktop {    
    @media (min-width: 1216px) and (max-width: 1407px) {
      @content;
    }
  }
  @if $breakpoint == s2-laptop {    
    @media (min-width: 1024px) and (max-width: 1215px) {
      @content;
    }
  }
  @if $breakpoint == hd-display {    
    @media only screen and (min-width: 1642px) {
      @content;
    }
  }
}
