
.align-center{
  text-align: center;
}
.align-right{
  text-align: center;
  @include from($desktop) {
    text-align: right;
  }
}
.align-left{
  text-align: center;
  @include from($desktop) {
    text-align: left;
  }
}

.is-inverted{
  color: $white;
}
