
@charset "utf-8";

// Import Bulma
@import "../../node_modules/bulma/bulma.sass";

//Import Libraries
@import "libraries/jquery-ui.css";
@import "libraries/magnific-popup";
@import "libraries/owl.carousel.min";
@import "libraries/owl.theme.default.min";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css');
@import "libraries/chosen.min.css";
@import "libraries/select2.min.css";
@import "libraries/keyboard.css";

// Initialise style variables
@import "settings.scss";


// Import component

// Utilities
@import "utilities/utilities.scss";
@import "utilities/mixins.scss";

// Elements
@import "elements/link";
// @import "elements/button";
// @import "elements/header";
// @import "elements/address";
// @import "elements/figure";
// @import "elements/video";


// Components
@import "components/topbar.scss";
@import "components/footer.scss";


//Sections
@import "sections/section.scss";

.columns {
  @extend .mx-0;
}
