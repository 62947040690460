
// .content{
//   a{
//     text-decoration: underline;
//   }
// }


a.adv-search{
  display: block;
  width: 100%;
  font-size: 0.8em;
  position: relative;
  color: $black;

  &:hover{
    text-decoration: underline;
  }

//  background-color: pink;
  top: 3.75em;
  padding: 1em;

  @include from($desktop) {
    top: 0.8em;
    padding: 0;
  }
}
