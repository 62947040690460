.section{
	padding: 3rem 1rem;
}
.search-bar {
	padding-top: 200px;
	padding-bottom: 250px;
	background-repeat: no-repeat;
	background-size: cover;
	.column.is-11-fullhd {
		@include from($fullhd){
			flex: none;
			width: 74.666667%;
		}
	}
	.section-content{
		color: $white;
		margin-bottom: 50px;
		@include devices(hd-display){
			margin-bottom: 78px;
		}
		h2 {
			font-size: 54px;
			font-weight: 400;
			padding-left: 30px;
			@include until($desktop) {
				font-size: 42px;
			}
			@include until($b-mobile){
				font-size: 34px;
			}
			@include devices(only-desktop){
				font-size: 45px;
			}
			@include from($fullhd){
				font-size: 54px;
				line-height: 75px;
				padding-left: 20px;
			}
			@include devices(hd-display){
				font-size: 70px;
				font-weight: 400;
				line-height: 75px;
				padding-left: 20px;
			}
		}
		p{
			line-height: 1.25;
			@include until($b-mobile){
				font-size: 15px;
			}
			@include devices(hd-display){
				font-size: 21px;
				word-spacing: 2px;
			}
		}
		@include until($desktop) {
			text-align: center;
			display: block;
		}
		@include until($b-mobile){
			margin-bottom: 30px;
		}
	}
	.section-form {
		background-color: #efe1c6;
		position: relative;
		// overflow: hidden;
		padding: 25px;
		box-shadow: 3px 18px 20px 0px rgb(60 62 55 / 47%);
		.columns {
			&.is-dekstop{
				@include until($desktop) {
					display: block;
				}
			}
		}
		.label {
			font-size: 0.65rem;
			font-weight: medium;
			margin-bottom: 0;
			@include devices(hd-display){
					font-size: 14px;
			}
		}
		.select select,
		.textarea, .input {
			background-color : #efe1c6;
			border: none;
			box-shadow: none;
			padding: 0 calc(0.75em - 1px) 0 0;
			z-index: 998;
			font-size: 22px;
			@include until($b-mobile){
				height: 38px;
			}
		}
		.select {
			@include until($desktop) {
				width: 100%;
			}
			.chosen-container {
				width: 170px !important;
				@include until($desktop) {
					width: 100% !important;
				}
				@include from($ul-dekstop){
					width: 225px !important;
				}
				@include until($b-mobile){
					width: 130px !important;
				}
			}
			.select2-container {
				width: 170px !important;
				@include until($desktop) {
					width: 100% !important;
				}
				@include from($ul-dekstop){
					width: 225px !important;
				}
				// @include until($b-mobile){
				// 	width: 130px !important;
				// }
			}
			select {
				min-width: 170px;
				padding-right: 35px;
				@include until($desktop) {
					width: 100%;
				}
				@include devices(hd-display){
					min-width: 225px;
				}
				@include until($b-mobile){
					min-width: 130px;
				}
			}
		}
		.select:not(.is-multiple):not(.is-loading)::after {
			border-color: $black;
			z-index: 99999;
			@include until($desktop) {
				content: none;
			}
			@include from($fullhd){
				top: 26px;
			}
			@include devices(laptop-desktop){
				top: 62%;
			}
		}
		.button {
			background-color: $primary;
			color: $white;
			font-size: 20px;
			font-weight: 600;
			border-radius: 0;
			padding: 15px 20px;
			border: none;
			min-height: 60px;
			@include until($desktop){
				position: absolute;
				left: 0;
				top: -12px;
				width: 100%;
			}
			// @include devices(only-tablet) {
			// 	position: absolute;
			// 	left: 0;
			// 	top: -25px;
			// 	width: 100%;
			// }
			.icon {
				margin-right: 10px;
				font-size: 24px;
			}
		}
		.right-shadow {
			&:after {
				@include from($desktop) {
					content: "";
					position: absolute;
					min-width: 16px;
					min-height: 230%;
					margin: 0;
					box-shadow: 8px -3px 7px -5px rgb(173 170 161 / 69%);
					background-color: transparent;
					top: -40px;
					right: 0;
				}
			}
		}
		.b-shadow {
			&:after {
				@include until($desktop) {
					content: "";
					position: absolute;
					min-width: 136%;
					min-height: 15px;
					margin: 0;
					box-shadow: 6px 7px 14px -5px rgb(173 170 161 / 69%);
					background-color: rgba(236, 232, 194, 0.1019607843);
					bottom: 0;
					right: -50px;
				}
				@include until($b-mobile){
					min-width: 120%;
					right: -15px;
				}
			}
			@include until($desktop) {
				margin-bottom: 20px;
			}
		}
		.b-desk {
			@include devices(s2-laptop){
				position: relative;
				top: -6px !important;
			}
		}
		.field{
			padding: 0 0.75rem 0;
			margin-bottom: 0;
			@include until($desktop) {
				width: 49%;
				display: inline-block;
			}
			&.b-mobile {
				@include until($desktop){
					width: 100%;
				}
			}
			&:nth-child(3) {
				@include from($fullhd){
					position: relative;
					top: -6px;
				}
				.right-shadow {
					&:after {
						@include from($desktop) {
							min-height: 315%;
						}
						@include devices(laptop-desktop){
							top: -46px;
						}
					}
				}
			}
			&:nth-child(4) {
				@include from($fullhd){
					position: relative;
					top: -6px;
				}
			}

		}
		.form-action {
			position: relative;
			@include until($tablet){
				margin-top: 0;
			}
			@include until($desktop){
				padding: 15px 0px 30px;
				margin-top: 15px;
			}
			@include devices(hd-display){
				//max-width: 190px;
			}
		}
		@include until($tablet){
			padding: 30px 0;
			margin: 0 15px;
		}
		@include until($desktop){
			margin: 0;
			padding: 30px 0;
		}
		@include from($fullhd){
			//padding: 30px 0px 30px 20px;
		}
	}
	@include until($desktop) {
	  padding-top: 50px;
		padding-bottom: 340px !important;
	}
	@include until($tablet) {
		padding-bottom: 490px !important;
	}
	@include until($b-mobile) {
		padding-bottom: 230px !important;
	}
	@include devices(hd-display){
		.column.is-11-fullhd{
			width: 94.666667% !important;
		}
	}
}
.our-stories {
	a {
		text-decoration: underline;
	}
	.column.is-11-fullhd {
		@include from($fullhd){
			width: 74.666667%;
		}
		@include devices(hd-display){
			width: 94.666667% !important;
			padding: 0.75rem 0;
		}
	}
	.section-content {
		padding: 150px 0;
		position: relative;
		@include until($tablet) {
			padding: 0 0 50px 0;
		}
		@include until($b-mobile){
			padding: 0 0 40px 0;
		}
		@include until($desktop){
			display: block;
		}
		h2 {
			color: $primary;
			font-size: 34px;
			font-weight: 600;
			margin-bottom: 30px;
			@include until($tablet) {
				// padding-top: 280px;
			}
			@include until($b-mobile) {
				// padding-top: 160px;
				font-size: 24px;
			}
			@include devices(s-mobile){
				margin-top: 50px;
			}
			@include from($fullhd){
				font-size: 32px;
				margin-bottom: 15px;
			}
			@include devices(hd-display){
				font-size: 38px;
			}
		}
		p{
			font-size: 22px;
			@include until($b-mobile) {
				font-size: 16px;
			}
			@include from($fullhd){
				font-size: 20px;
			}
			strong {
				a {
					color: black;
				}
			}
		}
		.section-img {
			position: relative;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				-webkit-box-shadow: 16px 14px 57px -3px rgba(148,145,148,1);
				-moz-box-shadow: 16px 14px 57px -3px rgba(148,145,148,1);
				box-shadow: 16px 14px 57px -3px rgba(148,145,148,1);
				@include until($tablet) {
					width: 90%;
					box-shadow: 0px 1px 20px 0px rgb(60 62 55 / 62%);
				}
			}
			@include tablet-only {
				margin-top: -250px;
			}
			@include devices(u-desktop){

			}
			@include until($desktop) {
				margin-top: -390px;
				width: 100%;
				text-align: center;
			}
			@include until($b-mobile) {
				margin-top: -165px;
			}
		}
		.s-left {
			@include until($tablet) {
					padding: 0;
			}
			@include until($desktop) {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				width: 100%;
			}
		}
		.s-right {
			@include until($b-mobile) {
				padding: 0;
			}
			@include from($desktop) {
				padding-left: 65px;
			}
			@include until($desktop) {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				width: 100%;
				text-align: center;
			}
		}
	}
}
// page banner
.page-banner {
		min-height:150px;
		background-repeat: no-repeat;
		background-size: cover;
		@include until($desktop) {
			min-height: 480px;
		}
}
#search-page {
		position: relative;
		.search-bar {
				padding: 1rem 1rem !important;
				height: 0;
				.column {
						position: relative;
				}
				.section-form {
						position: relative;
						left: 0;
						top: -110px;
						width: 100%;
						@include until($tablet) {
							width: auto;
						}
						@include until($desktop) {
							top: -455px;
						}
				}
		}
		.search-results {
				&.section {
						@include until($tablet) {
								padding: 50px 20px;
						}
				}
				.results-count {
						font-size: 34px;
						margin-bottom: 30px;
						@include until($tablet) {
							font-size: 28px;
						}
				}
				table{
						width: 100%;
						text-align: center;
						thead {
								background-color: $black;
								th {
										font-size: 14px;
										color: #c1bcbc;
										text-align: center;
										border: none;
										text-decoration: none;
										padding: 10px 15px;
										border-bottom: 3px solid $white;
								}
						}
						tbody{
								tr{
										background-color: #f7f0e2;
										border-bottom: 3px solid $white;
										&:hover{
											background-color: #efe1c6;
										}
								}
								td{
										border: none;
										font-size: 16px;
										padding: 20px;
										color: $black;
								}
						}
				}
				.pagination-list {
						justify-content: center !important;
				}
				.no_entries {
					font-size: 18px;
					line-height: 18px;
					padding-top: 12px;
					float: right;
					@include until($bm-mobile){
						float: none;
						display: block;
						margin-bottom: 15px;
					}
				}
				.pagination-next {
						i {
								padding-left: 5px;
						}
				}
				.pagination-previous {
						i {
								padding-right: 5px;
						}
				}
				.clickable-row {
						cursor: pointer;
				}
				@include until($desktop) {
					position: relative;
				}
		}
}

//Person details page
.person-details {
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	@include until($desktop) {
		overflow: hidden;
	}
	.container {
		&.m-container {
			@include until($b-large) {
				max-width: 100%;
			}
		}
	}
	.meta-data {
		margin-top: 0;
	}
	.m-overlay{
		display: none;
		@include until($desktop) {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 16%;
			right: 0;
			bottom: 0;
			background-color: rgb(2 2 2 / 25%);
			z-index: 0;
			cursor: pointer;
		}
	}
	.meta-data {
		// &:before {
		// 	content: "";
		// 	position: absolute;
		// 	min-width: 25px;
		// 	min-height: 310%;
		// 	margin: 0;
		// 	box-shadow: 8px -3px 7px -5px rgb(173 170 161 / 69%);
		// 	background-color: #ece8c21a;
		// 	top: -45px;
		// 	right: 0;
		// }
		@include until($desktop) {
			display: block;
		}
	}
	.section-details {
		flex: none;
		width: 35.666666%;
		padding: 60px 60px 60px 110px;
		color: $white;
		position: relative;
		z-index: 99999;
		@include desktop-only {
			padding: 60px 40px;
		}
		@include until($desktop) {
			width: 100%;
		}
		@include until($b-mobile) {
			padding: 50px 30px 30px;
		}
		.overlay{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgb(2 2 2 / 69%);
			z-index: -1;
			cursor: pointer;
			display: none;
			@include from($desktop) {
				display: block;
			}
		}
		.name {
			font-size: 32px;
			font-weight: 400;
			margin-bottom: 50px;
			padding-left: 0.75rem;
			padding-right: 0.75rem;
			@include desktop-only {
				margin-bottom: 40px;
			}
			@include until($b-mobile) {
				font-size: 28px;
				margin-bottom: 30px;
			}
		}
		.label {
			color: #dbd1b4;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 5px;
		}
		.detail {
			font-size: 24px;
			@include until($b-mobile) {
				font-size: 20px;
			}
			@include from($desktop) {
				width: 100% !important;
				margin-bottom: 0;
			}
		}
	}
	.section-images {
		flex: none;
		width: 63.444444%;
		padding: 50px 80px 60px;
		@include desktop-only {
			padding: 130px 80px 100px;
		}
		@include until($desktop) {
			width: 100%;
			padding: 10px 30px 100px;
		}
		@include until($tablet) {
			padding: 10px 15px 100px;
		}
		.owl-carousel {
			img {
				max-height: 520px;
				object-fit: contain;
			}
			.owl-stage-outer {
				@include from($bm-mobile) {
					min-height: 520px;
				}
			}
		}
		.owl-dots {
			text-align: right;
			padding-right: 20px;
			@include until($desktop) {
				text-align: center;
			}
			.owl-dot {
				span {
					background: #869791;
					width: 15px;
					height: 15px;
					margin: 5px 4px;
				}
				&.active {
					span{
						background: $primary;
					}
				}
				&:hover {
					span{
						background: $primary;
					}
				}
			}
		}
	}
	.b-row {
		height: 0;
		padding: 0;
		.b-search {
			position: absolute;
			top: -30px;
			z-index: 99999;
			@include until($desktop) {
				left: 30px;
				top: -83px;
			}
			@include until($tablet) {
				left: 15px;
				top: -66px;
			}
			.b-button {
				&.back {
					padding: 20px;
					margin-right: 15px;
					background-color: #34342e;
					@include until($tablet) {
						margin-right: 10px;
						padding: 10px 20px 15px 15px;
					}
					.icon {
						padding-right: 0;
					}

				}
			}
		}
		.b-story {
			position: absolute;
			right: 0;
			top: -30px;
			z-index: 99999;
			@include until($desktop) {
				right: 30px;
				top: -83px;
			}
			@include until($tablet) {
				right: 15px;
				top: -70px;
			}
			.b-share {
				padding: 20px;
				margin-left: 15px;
				background-color: $red;
				top: -3px;
    			position: relative;

				@include until($tablet) {
					margin-left: 10px;
					padding: 15px 20px 15px 15px;
					top: 3px;
				}
				.icon {
					padding-right: 0;
					font-size: 30px;
				}
			}
		}
		a {
			cursor: pointer;
		}
		.b-button {
			padding: 20px 30px 20px 45px;
			@include until($tablet) {
				padding: 10px 20px 15px 15px;
				font-size: 16px;
			}
			.icon {
				font-size: 38px;
				padding-right: 40px;
				@include until($tablet) {
					font-size: 18px;
					padding-right: 0;
					height: 30px;
					width: 25px;
				}
			}
			.new_search {
				@include until($tablet) {
					padding-left: 10px;
				}
			}
		}
	}

}
.family-stories {

		box-shadow: 0 9px 20px 1px rgba(0,0,0,0.22);
		-webkit-box-shadow: 0 9px 20px 1px rgba(0,0,0,0.22);
		-moz-box-shadow: 0 9px 20px 1px rgba(0,0,0,0.22);

		padding: 50px 0 0;
		min-height: 160px;
		@include until($desktop) {
			padding: 0;
		}
		a {
			text-decoration: underline;
		}
		.family-story {
			padding: 80px 0;
			@include until($tablet) {
				padding: 80px 20px;
			}
			@include until($b-mobile) {
				padding: 80px 15px;
			}
			&:nth-child(even) {
				background-color: #f7f7f7;
			}
			a {
				text-decoration: underline;
			}
			.author-details {
				padding: 30px 50px 50px 100px;
				@include until($desktop){
					text-align: center;
					margin: 0 auto;
				}
				@include until($tablet) {
					padding: 15px 0 50px;
					text-align: center;
				}
				h3 {
					color: $primary;
					font-size: 32px;
					margin-bottom: 20px;
					font-weight: 600;
					@include until($b-mobile) {
						font-size: 28px;
					}
				}
				p{
					color: #7b7b7b;
					font-size: 16px;
				}
			}
			.section-video {
				iframe {
					width: 100%;
					height: 600px;
					@include devices(only-desktop){
						height: 530px;
					}
					@include devices(only-tablet){
						min-width: 380px;
						height: 380px;
					}
					@include until($tablet) {
						min-height: 400px;
					}
					@include until($b-mobile) {
						min-height: 280px;
						height: 280px;
					}
				}
			}
			.section-summary {
				padding: 50px 100px;
				@include until($tablet) {
					padding: 50px 0;
				}
				@include until($bm-mobile) {
					padding: 50px 0;
				}
				p{
					font-size: 20px;
				}
			}
			.section-body {
				padding: 0 100px;
				@include until($tablet) {
					padding: 0;
				}
				p{
					margin-bottom: 16px;
				}
			}
			.section-gallery {
				margin-bottom: 50px;
				@include devices(only-tablet){
					padding: 0 30px;
				}

				.owl-carousel {
					.owl-item {
						img{
							max-height: 350px;
							object-fit: cover;
						}
					}
				}

			}
		}
}
.mfp-wrap {
	z-index: 1000000;
}
.mfp-bg {
	z-index: 999999;
	opacity: 0.9;
}
#emailModal {
	z-index: 1000000;
	img {
		width: 100%;
	    height: 100%;
	    object-fit: cover;
		@include until($tablet) {
			height: 205px;
		}
	}
	.modal-content {
		position: relative;
		top:-70px;
		background-color: #fff;
		overflow: hidden;
		.details {
		    padding: 60px 40px 40px 60px;
			@include until($tablet) {
				padding: 20px 40px 30px 30px;
			}
			@include until($bs-mobile){
				padding: 20px;
			}
		    h2 {
		    	font-size: 32px;
		    }
		    p{
		    	font-size: 22px;
		    }
		}
		label{
			color: $red;
		}
		@include tablet {
			width: auto;
		}
		@include until($tablet) {
			overflow-y: scroll;
			max-height: 100%;
			height: 95%;
			width: 90%;
			top: 0;
		}
	}
	.model-close-btn {
		position: absolute;
		right: 10px;
		top: 10px;
		color: #000;
		i {
			font-size: 26px;
		}
		@include until($tablet) {
			color: #fff;
		}
	}
	.b-submit {
		background-color: $primary;
		color: $white;
		font-size: 20px;
		font-weight: 600;
		border-radius: 0;
		padding: 20px 25px;
		border: none;
		margin-top: 15px;
	}
}
.share-stories {
	padding: 200px 0 150px;
	background-repeat: no-repeat;
	background-size: cover;
	@include until($desktop){
		padding: 150px 15px 100px;
	}
	.column.is-11-fullhd {
		width: 86.666667%;
	}
	.column.is-12-desktop {
		flex: none;
		width: 100%;
	}
	.section-img {
		position: relative;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
				-webkit-box-shadow: 16px 14px 57px -3px rgba(148,145,148,1);
				-moz-box-shadow: 16px 14px 57px -3px rgba(148,145,148,1);
				box-shadow: 16px 14px 57px -3px rgba(148,145,148,1);
		}
	}
	.section-content {
		position: relative;
		@include until($tablet) {
			padding: 0 0 50px 0;
		}
		@include until($b-mobile){
			padding: 0 0 40px 0;
		}
		@include until($desktop){
			display: block;
		}
		a {
			text-decoration: underline;
			word-break: break-all;
		}
		.s-left {
			@include until($tablet) {
				padding: 0;
			}
			@include until($desktop) {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				width: 100%;
			}
		}
		.s-right {
			@include until($b-mobile) {
				padding: 0;
			}
			@include from($desktop) {
				padding-left: 65px;
			}
			@include until($desktop) {
				flex-basis: 0;
				flex-grow: 1;
				flex-shrink: 1;
				width: 100%;
				text-align: center;
			}
		}
	}
	h2{
		color: $red;
		font-size: 32px;
		margin-bottom: 20px;
		@include until($desktop){
			margin-top: 50px;
			margin-bottom: 50px;
		}
	}
	p{
		color: $black;
		font-size: 20px;
		strong {
			a{
				color: $black;
			}
		}
		@include until($tablet){
			font-size: 18px;
		}
		&.py-5 {
			@include until($desktop){
				padding-top: 40px !important;
			}
		}
	}
	.btn-rows {
		padding-top: 4em;
		@include until($desktop){
			padding-top: 50px;
		}
		.b-button {
			padding: 20px 40px;
			@include until($tablet) {
				padding: 10px 20px 15px 15px !important;
				font-size: 16px;
			}
		}
		.b-story {
			position: absolute;
			right: 0;
			@include until($desktop){
				right: 15px;
			}
			button {
				background-color: $white;
				color: $red;
				padding: 25px 60px 25px 70px;
				i {
					font-size: 38px;
					padding-right: 55px;
					@include until($tablet) {
						font-size: 20px;
    					padding-right: 10px;
					}
				}
			}
		}
		.b-search {
			button {
				padding: 20px 30px 20px 55px;
				i {
					font-size: 38px;
					padding-right: 55px;
					@include until($tablet) {
						font-size: 20px;
    					padding-right: 10px;
					}
				}
			}
		}
	}
}
.pagination {
	padding-top: 25px;
	.pagination-link.is-current{
		background-color: $red;
		border-color: $red;
		color: #fff;
	}
	i{
		color: $red;
	}
}

.chosen-container-single {
	.chosen-single {
		height: 2.5em !important;
	    background-color: transparent !important;
		background: transparent !important;
	    border: none !important;
	    box-shadow: none !important;
	    padding: 14px calc(0.75em - 1px) 0 0 !important;
	    z-index: 99999;
	    font-size: 22px;
	    padding-top: 15px;
	}
	.chosen-drop {
		background-color: #fff;
	    padding: 14px;
	    border-radius: 5px;
	    box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
	    margin-top: -5px;
		border: none;
		@include from($select-large) {
			width: 115%;
			left: -23px;
		}
		.chosen-search input[type=text] {
			background-color: #fff;
			padding: 12px;
		    height: 40px;
		    font-size: 18px;
		    margin-bottom: 5px;
		    border-radius: 2px;
		}
		.chosen-results li {
			font-size: 18px;
		    line-height: 1.4;
		    padding: 5px 10px;
		    border-radius: 2px;
		    &.highlighted{
				background-color:$red !important;
				background-image:-webkit-gradient(linear,left top,left bottom,color-stop(20%, $red),color-stop(90%,$red));
				background-image:linear-gradient($red 20%, $red 90%);
				color:#fff;
			}
		}
	}
}
.chosen-container-active.chosen-with-drop {
	.chosen-single {
		background-color: #efe1c6;
	    border: none;
	    box-shadow: none;
	    padding: 0 calc(0.75em - 1px) 0 0;
	}
}

.select2-container--default {
	.select2-selection--single
	{
		.select2-selection__rendered
		{
			height: 2.5em !important;
		    background-color: transparent !important;
		    background: transparent !important;
		    border: none !important;
		    box-shadow: none !important;
		    padding: 14px calc(0.75em - 1px) 0 0 !important;
		    z-index: 99999;
		    font-size: 22px;
		    padding-top: 15px;
		    @include devices(laptop-desktop){
		    	font-size: 18px;
		    	padding: 12px calc(0.75em - 1px) 0 0 !important;
		    }
		}
		.select2-selection__arrow {
			display: none;
		}
	}
	.select2-results__option--highlighted.select2-results__option--selectable {
    	background-color:$red !important;
		background-image:-webkit-gradient(linear,left top,left bottom,color-stop(20%, $red),color-stop(90%,$red));
		background-image:linear-gradient($red 20%, $red 90%);
		color:#fff;
    }
    .select2-search--dropdown {
		position: relative;
	    z-index: 1010;
	    margin: 0;
	    padding: 3px 4px;
	    white-space: nowrap;
	    .select2-search__field {
	    	background-color: #fff;
		    padding: 12px;
		    height: 40px;
		    font-size: 18px;
		    margin-bottom: 5px;
		    border-radius: 2px;
		    box-shadow: none;
	    }
	    .select2-results__options {
	    	color: #444;
		    position: relative;
		    overflow-x: hidden;
		    overflow-y: auto;
		    margin: 0 4px 4px 0;
		    padding: 0 0 0 4px;
		    max-height: 240px;
		    .select2-results__option {
		    	font-size: 18px;
		    	border-radius: 2px;
		    	word-wrap: break-word;
		    }
	    }
	}
}
.select2-results__option--selectable {
	word-wrap: break-word;
}
.select2-container--open {
	.select2-dropdown--below {
		background-color: #fff;
	    padding: 14px;
	    border-radius: 5px;
	    box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
	    margin-top: -5px;
		border: none;
		top: 33px;
		@include from($select-large) {
			width: 258px !important;
			left: -23px;
		}
		@include devices(s2-desktop) {
			width: 245px !important;
    		left: -22px;
		}
		@include devices(s2-laptop) {
			width: 195px !important;
    		left: -24px;
		}
	}
}

.select2-container--default .select2-results>.select2-results__options {
	color: #444;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 4px 0;
    padding: 0 0 0 4px;
    max-height: 240px;
}
.autocomplete-suggestions {
	color: white;
	background-color: black;
}
// .chosen-single {
// 	height: 2.5em !important;
//     background-color: transparent !important;
// 	background: transparent !important;
//     border: none !important;
//     box-shadow: none !important;
//     padding: 14px calc(0.75em - 1px) 0 0 !important;
//     z-index: 99999;
//     font-size: 22px;
//     padding-top: 15px;
// }
// .chosen-container-active.chosen-with-drop .chosen-single
// {
// 	background-color: #efe1c6;
//     border: none;
//     box-shadow: none;
//     padding: 0 calc(0.75em - 1px) 0 0;
// }
// .chosen-container-single .chosen-drop {
// 	background-color: #fff;
//     padding: 14px;
//     border-radius: 5px;
//     box-shadow: 0 2px 20px rgb(0 0 0 / 20%);
// }
// .chosen-container-single .chosen-search input[type=text] {
// 	background-color: #fff;
// }
// .chosen-container-single .chosen-drop {
// 	margin-top: -5px;
// 	border: none;
// }
// .chosen-container .chosen-drop {
//     max-width: 210px;
// }
// .chosen-container .chosen-results li {
// 	font-size: 18px;
//     line-height: 1.4;
//     padding: 5px 10px;
//     border-radius: 2px;
// }
// .chosen-container .chosen-results li.highlighted{
// 	background-color:$red !important;
// 	background-image:-webkit-gradient(linear,left top,left bottom,color-stop(20%, $red),color-stop(90%,$red));
// 	background-image:linear-gradient($red 20%, $red 90%);
// 	color:#fff;
// }
// .chosen-container-single .chosen-search input[type=text] {
//     padding: 12px;
//     height: 40px;
//     font-size: 18px;
//     margin-bottom: 5px;
//     border-radius: 2px;
// }
// /* width */
// ::-webkit-scrollbar {
// width: 3px;
// border-radius: 6px
// }

// /* Track */
// ::-webkit-scrollbar-track {
// background:  transparent;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// background: #888;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// background: #000;
// }
/* total width */
.select2-results__options::-webkit-scrollbar {
    background-color:#fff;
    width:10px;
}

/* background of the scrollbar except button or resizer */
.select2-results__options::-webkit-scrollbar-track {
    background-color:#fff
}
.select2-results__options::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
}

/* scrollbar itself */
.select2-results__options::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:8px;
    border:3px solid #fff
}
.select2-results__options::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:2px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
.select2-results__options::-webkit-scrollbar-button {display:none}

.ui-menu.ui-widget,
.ui-keyboard.ui-widget-content{
	z-index: 99999999;
}

.select2-container--default {
	.select2-selection--single {
		background-color: transparent;
		border: 0;
		border-radius: 0;
		padding-right: 10px;
	}
}



.disclaimer{
	color: $white;
	font-size: 0.8em;
	padding: 1.5em;
	display: inline-block;
	@include until($desktop) {
		padding-left: 50px;
		position: relative;
	}
	@include until($tablet) {
		padding-left: 30px;
	}

	&.invert{
		color: $black;
	}

	details>summary {
	  list-style-type: none;
	  outline: none;
	  cursor: pointer;
	}

	details>summary::-webkit-details-marker {
	  display: none;
	}

	details>summary::before {
	  content: '+ ';
	}

	details[open]>summary::before {
	  content: '- ';
	}

	details[open]>summary {
	  margin-bottom: 0.5rem;
	}

}
.no_results {
	min-height: 200px;
}
.output_message {
	&.success {
		padding-top: 35px;
	    font-size: 22px;
	    color: green;
	    font-weight: 500;
	}
}
.output_message {
	&.error {
		padding-top: 10px;
	    font-size: 20px;
	    color: $red;
	    font-weight: 500;
	}
}
