
$topBarPadding: 20px;

.top-bot-bar{
  background-color: $red;
  //position: fixed;
  z-index: 99999;
  top: 0;
  left:0;
  width:100%;
  padding: $topBarPadding;
  color: $white;

  li{
    font-size: 1.1em;
    display: inline-block;

    a.button{
      text-decoration: none;
      color: $white;
    }

    &:last-child{
      a{
        background-color: $red;
      }
    }
  }


}


/* Modifiers */
/* =========================================== */
.top-bot-bar{
  &.active{
    @include animate(0.4s);
    padding: calc($topBarPadding / 2);
    background-color: $white;
    @include box_shadow(4, 'bottom');


    .sitelogo{
      @include animate;
      transform: scale(0.8) translateX(-10%);

      &:before{
        @include animate;
        background-image: url('../img/aom-icon-white.png');
        opacity: 0;
      }
      &:after{
        @include animate;
        background-image: url('../img/aom-icon-colour.png');
        opacity: 1;
      }


      svg{
        top: 0.3em;
        left: 0;
      }
      g{
        fill:$red;
      }
    }


    a.button{
      text-decoration: none;
      color: $red;
    }

    li:last-child{
      a{
        color: $white;
      }
    }
  }
}

.top-bot-bar{
  position: relative;
  overflow: hidden;
  .container {
		&.m-container {
			@include until($b-large) {
				max-width: 100%;
			}
		}
	}
  .column.is-3-fullhd {
    @include from($fullhd){
      width: 23.40%;
    }
    @include devices(hd-display){
      width: 23.40%;
    }
  }
  .sitelogo{
    position: relative;
    min-width: 340px;
    &:after {
      content: "";
      position: absolute;
      min-width: 10px;
      min-height: 150%;
      /* max-height: 120vh; */
      margin: 0;
      box-shadow: 9px 1px 8px 0px rgb(118 32 32 / 32%);
      background-color: #E12328;
      -webkit-transform: skew(325deg);
      -moz-transform: skew(325deg);
      -o-transform: skew(325deg);
      top: -20px;
      right: 0;
      @include from($tablet){
        top: -15px;
      }
      @include from($fullhd){
        min-height: 128%;
        box-shadow: 9px 1px 8px 0px rgb(118 32 32 / 32%);
      }
      @include devices(hd-display){
        min-height: 130%;
        top: -30px;
      }
    }
    .logo{
      height: 95px;
      width: auto;
      @include animate(0.4s);
      @include until($desktop) {
        height: 80px;
      }
      @include devices(only-desktop){
				height: 80px;
			}
      @include devices(hd-display){
        height: 120px;
      }
      @include until($bs-mobile){
        height: 55px;
        width: 100px;
      }
    }
    @include until($desktop){
      min-width: 210px;
    }
    @include until($b-mobile){
      min-width: 175px;
    }
    @include until($bs-mobile){
      min-width: 115px;
    }
    @include from($fullhd){
      min-width: 298px;
    }

  }
  .site-heading {
    display: table;
    width: 100%;
    height: 90%;
    h1 {
      display: table-cell;
      vertical-align: middle;
      @include until($b-mobile){
        font-size: 14px !important;
      }
      @include devices(hd-display){
        font-size: 32px !important;
      }
    }
    @include until($desktop){
      text-align: right;
    }
    @include until($b-mobile){
      text-align: right;
    }
    .icon {
      position: relative;
      top: -4px;
    }
  }
  .column{
    @include until($b-mobile){
      padding: 0;
    }
  }
  @include until($desktop){
    padding: 15px;
  }
  @include from($desktop) {
    padding: 10px 10px 5px;
  }
  @include from($fullhd){
    padding: 15px 10px 10px;
  }
}

@include desktop-only {
  .top-bot-bar{
    .sitelogo{
      min-width: 280px;
    }
  }
}

@include tablet-only {
  .top-bot-bar {
    .sitelogo {
      min-width: 230px;
    }
    .site-heading {
      text-align: right;
      h1 {
        font-size: 23px !important;
      }
    }
  }
}

// @include mobile {
//   .top-bot-bar {
//     .sitelogo {
//       min-width: 180px;
//     }
//     .site-heading {
//       text-align: right;
//       h1 {
//         font-size: 18px !important;
//       }
//     }
//   }
// }