
#footer{
  &.top-bot-bar {
    overflow: hidden;
    .sitelogo
    {
      &:after
      {
        min-height: 130%;
        top: -12px;
        @include devices(only-tablet) {
          min-height: 160%;
          top: -15px;
        }
        @include devices(only-desktop) {
          min-height: 160%;
          top: -15px;
        }
      }
    }
  }
  
  a{
    color: $white;
    font-size: 20px;
    font-weight: 500;
    i {
      font-size: 32px;
      position: relative;
      top: 8px;
      left: -10px;
      @include until($b-mobile){
        font-size: 24px;
      }
    }
    @include until($b-mobile){
      font-size: 16px;
    }
  }
}